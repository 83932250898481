@import '../node_modules/leaflet/dist/leaflet.css';
@import '../node_modules/bulma/css/bulma.min.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

#container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

#panel {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  max-width: 50%;
  min-width: 450px;
  width: 600px;
  overflow: scroll;
}

#address-field-0 {
  margin-bottom: 4px;
}

#border {
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.403);
}

.tabs {
  flex-shrink: 0;
}

#title {
  padding-bottom: 12px;
}

#buttons-container {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

/* #add-adress-button-field {
  padding-top: 8px;
} */

#controls-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1;
  padding-bottom: 16px;
}

.button.addon-button {
  width: 50px;
}

.pin {
  height: 10px;
  padding-right: 8px;
  margin-top: 4px;
}

.button {
  align-self: center;
}

#map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

#map {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#friendship-denied-banner {
  position: absolute;
  flex-direction: row;
  text-align: center;
  top: 50px;
  left: 50%;
  margin-left: -175px;
  width: 350px;
  z-index: 999;
  visibility: hidden;
  border-radius: 40px;
  
}

#mobile-footer {
  display: none;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#friendship-denied-banner.show {
  visibility: visible; /* Show the snackbar */
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

/* Hide the snackbar when clicking on a button (class added with JavaScript) */
#friendship-denied-banner.hide {
  visibility: visible;
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

#lga-of-concern-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding-left: 4px;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 50px; opacity: 1;}
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 50px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: 0; opacity: 0;}
}

@keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: 0; opacity: 0;}
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 900px) {
  #container {
    flex-direction: column;
    justify-content: left;
    height: auto;
    overflow-y: scroll;
    width: 100%;
  }

  #panel {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }

  #map-container {
    height: 500px;
  }

  #desktop-footer {
    display: none;
  }

  #mobile-footer {
    display: flex;
    width: 100%;
    padding: 20px;
  }
}

.hidden {
  display: none !important;
}
